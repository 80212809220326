import React, { useEffect } from "react";
import Header from "../components/nav/Header";
import { Link } from "react-router-dom";

function RefundPolicy() {
  function handleMail() {
    window.open("mailto:contact@shopad.in");
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="sm:pt-[185px] pt-[130px] sm:pb-[150px] pb-[70px] bg-[#EFF0F5] sm:px-[100px] flex flex-col sm:gap-[40px] gap-[20px] px-[20px]">
        <h6 className="font-sans font-[700] text-[40px] leading-[44px] text-[#1D1D45]">
          Refund Policy:
        </h6>
        <div>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            We appreciate your business and strive to ensure your satisfaction
            with our products. However, please note that we do not offer refunds
            for any purchases made through our platform.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Explanation:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            Our policy is in line with the legal requirements and is designed to
            be clear and transparent for our customers. We believe that by
            providing detailed information about our refund policy, we can
            manage our customers’ expectations.
          </p>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90]">
            It's important to note that while we do not offer refunds, we are
            committed to addressing any issues or concerns you may have with
            your purchase. If you encounter any problems with the products
            you've bought from us, please don't hesitate to reach out to our
            customer service team. We will do our best to find a satisfactory
            resolution for you.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Legal Compliance:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            Our refund policy is in accordance with the legal requirements and
            regulations governing refund and return policies for businesses. We
            aim to foster trust and positive relationships with our customers
            while ensuring legal compliance.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Conclusion:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            We understand the importance of a well-crafted refund policy, and we
            are committed to ensuring that our customers have a clear
            understanding of our terms and conditions.
          </p>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90]">
            If you have any further questions or concerns regarding our refund
            policy, please feel free to contact us at&nbsp;
            <span className="underline cursor-pointer" onClick={handleMail}>
              contact@shopad.in
            </span>
            . We are here to assist you and provide the best possible shopping
            experience.
          </p>
        </div>
      </div>
      <div className="bg-[#0b0d19] pt-[100px] sm:px-0 px-[20px] pb-[50px]">
        <h6 className="font-sans font-[700] text-[36px] leading-[44px] text-center text-[#FFFFFF] pb-[13px]">
          Refund Policy
        </h6>
        <p className="font-[Outfit] max-w-[600px] w-full m-auto font-[400] pb-[62px] text-[24px] leading-[30px] text-center text-[#606483]">
          ShopAd is coined up with tag line “Shop with Advertisement” because
          quick expansion and control over business will go hand in hand with
          technology
        </p>
        <div className="bg-[#1E233D] max-w-[1080px] m-auto h-[1px] mt-[10px] mb-[80px]"></div>
        <div className="flex flex-col gap-[24px] w-full max-w-[1080px] m-auto">
          <div className="flex justify-between">
            <div className="sm:flex hidden flex-1"></div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link to="/">
                <img
                  src="assets/icon1.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon3.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon2.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-[20px]">
            <div className="flex flex-1 font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]">
              © 2022 ShopAd Ventures, all rights reserved
            </div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link
                to="/"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Home
              </Link>
              <Link
                to="/privacy-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Privacy Policy
              </Link>
              <Link
                to="/refund-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Refund Policy
              </Link>
              <Link
                to="/terms-condition"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Terms and Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RefundPolicy;
