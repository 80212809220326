import React, { useEffect } from "react";
import Header from "../components/nav/Header";
import { Link } from "react-router-dom";

function TermsCondition() {
  function handleMail() {
    window.location.href = "mailto:contact@shopad.in";
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="sm:pt-[185px] pt-[130px] sm:pb-[150px] pb-[70px] bg-[#EFF0F5] sm:px-[100px] flex flex-col sm:gap-[40px] gap-[20px] px-[20px]">
        <h6 className="font-sans font-[700] text-[40px] leading-[44px] text-[#1D1D45]">
          Introduction:
        </h6>
        <div>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            Welcome to ShopAd, and thank you for choosing our mobile app. Before
            using our app, please carefully review the following terms and
            conditions that govern your use of the ShopAd mobile application.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Agreement to Terms:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            By downloading, installing, or using the ShopAd mobile app, you
            agree to be bound by these terms and conditions. If you do not agree
            with any part of these terms, you may not use our mobile app.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Use of the Mobile App:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            ShopAd grants you a non-transferable, non-exclusive, and revocable
            license to use the ShopAd mobile app for your personal,
            non-commercial use. You may not modify, reproduce, distribute, or
            create derivative works based on the ShopAd mobile app.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            User Conduct:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            When using the ShopAd mobile app, you agree to comply with all
            applicable laws and regulations. You may not engage in any conduct
            that restricts or inhibits any other user from using the app, or
            that could damage, disable, overburden, or impair the functioning of
            the app.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Intellectual Property:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            All content, trademarks, and intellectual property rights related to
            the ShopAd mobile app are the property of ShopAd and are protected
            by applicable copyright and intellectual property laws. You may not
            use, reproduce, or distribute any content from the app without our
            express written consent.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Limitation of Liability:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            In no event shall ShopAd, its directors, employees, or affiliates be
            liable for any direct, indirect, incidental, special, or
            consequential damages arising from the use of the ShopAd mobile app.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Termination:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            ShopAd reserves the right to terminate or suspend your access to the
            mobile app at any time, without prior notice, for any reason,
            including if we believe that you have violated these terms and
            conditions.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Changes to Terms and Conditions:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            We reserve the right to update or modify these terms and conditions
            at any time. Any changes will be effective immediately upon posting
            within the app.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Governing Law:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            These terms and conditions are governed by and construed in
            accordance with the laws of the jurisdiction in which your business
            is located, and you irrevocably submit to the exclusive jurisdiction
            of the courts in that jurisdiction.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Contact Us:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            If you have any questions or concerns about our terms and
            conditions, please contact us at &nbsp;
            <span onClick={handleMail} className="underline cursor-pointer">
              contact@shopad.in
            </span>
            . We are here to provide you with the best possible support and
            assistance.
          </p>
        </div>
      </div>
      <div className="bg-[#0b0d19] pt-[100px] sm:px-0 px-[20px] pb-[50px]">
        <h6 className="font-sans font-[700] text-[36px] leading-[44px] text-center text-[#FFFFFF] pb-[13px]">
          Terms and Condition
        </h6>
        <p className="font-[Outfit] max-w-[600px] w-full m-auto font-[400] pb-[62px] text-[24px] leading-[30px] text-center text-[#606483]">
          Thank you for taking the time to review our terms and conditions. We
          hope you enjoy using ShopAd.
        </p>
        <div className="bg-[#1E233D] max-w-[1080px] m-auto h-[1px] mt-[10px] mb-[80px]"></div>
        <div className="flex flex-col gap-[24px] w-full max-w-[1080px] m-auto">
          <div className="flex justify-between">
            <div className="sm:flex hidden flex-1"></div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link to="/">
                <img
                  src="assets/icon1.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon3.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon2.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-[20px]">
            <div className="flex flex-1 font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]">
              © 2022 ShopAd Ventures, all rights reserved
            </div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link
                to="/"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Home
              </Link>
              <Link
                to="/privacy-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Privacy Policy
              </Link>
              <Link
                to="/refund-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Refund Policy
              </Link>
              <Link
                to="/terms-condition"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Terms and Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsCondition;
