import React, { useEffect } from "react";
import Header from "../components/nav/Header";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  function handleMail() {
    window.location.href = "mailto:contact@shopad.in";
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="sm:pt-[185px] pt-[130px] sm:pb-[150px] pb-[70px] bg-[#EFF0F5] sm:px-[100px] flex flex-col sm:gap-[40px] gap-[20px] px-[20px]">
        <h6 className="font-sans font-[700] text-[40px] leading-[44px] text-[#1D1D45]">
          Privacy Policy:
        </h6>
        <div>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            At ShopAd, we are committed to protecting the privacy and security
            of our customers' personal information. This privacy policy outlines
            how we collect, use, and safeguard the personal data you provide to
            us.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Information Collection:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            When you visit our website or make a purchase, we may collect
            personal information such as your name, email address, shipping
            address, and payment details. We use this information solely for the
            purpose of processing your orders and providing customer support.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Email Contact:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            For any inquiries or communication, you can reach us at&nbsp;
            <span onClick={handleMail} className="underline cursor-pointer">
              contact@shopad.in
            </span>
            . We assure you that your email address will only be used for
            communication related to your orders, account management, or
            providing information about our products and services.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Third-Party Disclosure:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties. This does not include trusted third
            parties who assist us in operating our website, conducting our
            business, or servicing you, as long as those parties agree to keep
            this information confidential.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Cookies:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            Our website may use cookies to enhance your shopping experience,
            track your preferences, and improve our website's functionality. You
            have the option to disable cookies in your browser settings, but
            this may affect the functionality of our website.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Legal Compliance:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            Our privacy policy is intended to comply with applicable laws and
            regulations regarding the collection, use, and protection of
            personal information.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Updates to Privacy Policy:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            We reserve the right to update our privacy policy as necessary. Any
            changes will be posted on this page, and you will be notified of any
            material changes to our privacy practices.
          </p>
        </div>
        <div>
          <h6 className="font-sans font-[700] text-[26px] leading-[34px] text-[#1D1D45]">
            Contact Us:
          </h6>
          <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] pt-[16px]">
            If you have any questions or concerns about our privacy policy or
            the handling of your personal information, please contact us at
            &nbsp;
            <span onClick={handleMail} className="underline cursor-pointer">
              contact@shopad.in
            </span>
            . We are dedicated to addressing any issues and providing you with
            the best possible support.
          </p>
        </div>
      </div>
      <div className="bg-[#0b0d19] pt-[100px] sm:px-0 px-[20px] pb-[50px]">
        <h6 className="font-sans font-[700] text-[36px] leading-[44px] text-center text-[#FFFFFF] pb-[13px]">
          Privacy Policy
        </h6>
        <p className="font-[Outfit] max-w-[600px] w-full m-auto font-[400] pb-[62px] text-[24px] leading-[30px] text-center text-[#606483]">
          ShopAd is coined up with tag line “Shop with Advertisement” because
          quick expansion and control over business will go hand in hand with
          technology
        </p>
        <div className="bg-[#1E233D] max-w-[1080px] m-auto h-[1px] mt-[10px] mb-[80px]"></div>
        <div className="flex flex-col gap-[24px] w-full max-w-[1080px] m-auto">
          <div className="flex justify-between">
            <div className="sm:flex hidden flex-1"></div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link to="/">
                <img
                  src="assets/icon1.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon3.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon2.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-[20px]">
            <div className="flex flex-1 font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]">
              © 2022 ShopAd Ventures, all rights reserved
            </div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link
                to="/"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Home
              </Link>
              <Link
                to="/privacy-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Privacy Policy
              </Link>
              <Link
                to="/refund-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Refund Policy
              </Link>
              <Link
                to="/terms-condition"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Terms and Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
