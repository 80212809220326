import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

function Header() {
  const [dropdown, setDropdown] = useState(false);
  function handleDropdown() {
    setDropdown(!dropdown);
  }

  return (
    <>
      <div className="flex w-full justify-between z-50 fixed top-0 items-center sm:px-[86px] px-[14px] bg-[#FFFFFF] py-[12px]">
        <div className="flex flex-1 items-center gap-[93px]">
          <Link to="/">
            <img
              src="assets/shopad-logo.png"
              className="object-cover w-[42px] h-[50px]"
              alt=""
            />
          </Link>
        </div>
        <div className="flex flex-1 justify-end">
          <div
            className="overlay"
            onClick={() => {
              handleDropdown();
            }}
            style={dropdown ? { right: 0 } : { right: "100%" }}
          ></div>
          {/* <button onClick={handleDropdown} className="sm:hidden block">
            <img
              src="/assets/ham.png"
              className="h-[30px] object-cover w-[30px]"
              alt=""
            />
          </button> */}
          <div
            className={`flex sm:relative navbar-item fixed sm:w-auto w-[250px] sm:bg-transparent bg-[#FFFFFF] sm:flex-row sm:items-center items-start sm:pl-0 pl-[20px] sm:pt-0 pt-[30px] flex-col sm:gap-[30px] gap-[20px] top-0 bottom-0 ${
              dropdown ? "right-0" : "sm:right-0 right-[-250px]"
            }`}
          >
            {/* <Link
              to="/"
              className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483] sm:text-[#2A2A2A]"
            >
              Home
            </Link>
            <Link
              to="/"
              className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483] sm:text-[#2A2A2A]"
            >
              Refund Policy
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
