import React, { useEffect } from "react";
import Header from "../components/nav/Header";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="overflow-hidden">
        <div className="sm:pt-[185px] pt-[130px] sm:pb-[150px] pb-[70px] bg-[#EFF0F5] sm:px-[100px] px-[20px]">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-[42px] items-center">
            <div>
              <h6 className="font-sans pb-[7px] font-[700] text-[40px] leading-[44px] text-[#1D1D45]">
                SHOPAD
              </h6>
              <p className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90] sm:pt-[30px] pt-[16px]">
                ShopAd is coined up with tag line “Shop with Advertisement”
                because quick expansion and control over business will go hand
                in hand with technology
              </p>
            </div>
            <div>
              <div className="relative">
                <div className="pointer-events-none mockup-bg">
                  <img src="assets/iphone-hero-bg.svg" alt="" />
                </div>
                <div className="max-w-[350px] w-full m-auto h-auto relative z-1">
                  <img src="assets/iphone1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:pt-[100px] pt-[30px] sm:pb-[100px] pb-[50px] bg-[#EFF0F5] sm:px-[100px] px-[20px]">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-[42px] items-center">
            <div className="relative">
              <div className="pointer-events-none mockup-bg">
                <img src="assets/iphone-feature-bg-01.svg" alt="" />
              </div>
              <div className="max-w-[350px] w-full m-auto h-auto relative z-1">
                <img src="assets/iphone2.png" alt="" />
              </div>
            </div>
            <div>
              <h6 className="font-sans pb-[7px] font-[700] text-[40px] leading-[44px] text-[#1D1D45]">
                About ShopAd App
              </h6>
              <ul className="list-disc flex flex-col gap-[14px] sm:mt-[30px] pt-[16px] pl-[16px]">
                <li className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90]">
                  Vocal for Local, Get best offers from Local Shops
                </li>
                <li className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90]">
                  Unbeatable Offer and Discounts - Choose the Right Shop at your
                  Nearby!
                </li>
                <li className="font-sans font-[500] text-[20px] leading-[30px] text-[#6B7A90]">
                  Discover the latest trends, Get at nearby shops
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#E68927] sm:pt-[156px] pt-[50px] sm:px-[92px] px-[20px] pb-[30px]">
        <div className="relative bg-[#1C2672] rounded-[12px] py-[68px] px-[46px]">
          <img
            src="/assets/icon1.png"
            className="sm:absolute relative sm:right-[100px] right-unset object-cover sm:h-[500px] h-[600px] sm:w-auto w-full bottom-0"
            alt=""
          />
          {/* <img
            src="assets/home/photo4.svg"
            className="absolute right-0 top-[-140px]"
            alt=""
          /> */}
          <h6 className="font-sans sm:text-left text-center font-[700] sm:text-[40px] text-[30px] sm:pt-0 pt-[50px] sm:leading-[44px] leading-[36px] text-[#FFFFFF] sm:pb-[54px] pb-[20px]">
            Download App Now
          </h6>
          <p className="font-[Outfit] font-[400] text-[18px] leading-[26px] max-w-[612px] text-[#FFFFFF]">
            ShopAd is coined up with tag line “Shop with Advertisement” because
            quick expansion and control over business will go hand in hand with
            technology
          </p>
          <div className="flex sm:flex-row flex-col gap-[30px] mt-[10px]">
            <a
              href="https://play.google.com/store/apps/details?id=com.shopad"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/home/icon9.svg" alt="" />
            </a>
            {/* <img src="assets/home/icon10.svg" alt="" /> */}
          </div>
        </div>
      </div>
      <div className="bg-[#0b0d19] pt-[100px] sm:px-0 px-[20px] pb-[50px]">
        <h6 className="font-sans font-[700] text-[36px] leading-[44px] text-center text-[#FFFFFF] pb-[13px]">
          Stay in the know
        </h6>
        <p className="font-[Outfit] max-w-[600px] w-full m-auto font-[400] pb-[62px] text-[24px] leading-[30px] text-center text-[#606483]">
          ShopAd is coined up with tag line “Shop with Advertisement” because
          quick expansion and control over business will go hand in hand with
          technology
        </p>
        <div className="bg-[#1E233D] max-w-[1080px] m-auto h-[1px] mt-[10px] mb-[80px]"></div>
        <div className="flex flex-col gap-[24px] w-full max-w-[1080px] m-auto">
          <div className="flex justify-between">
            <div className="sm:flex hidden flex-1"></div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link to="/">
                <img
                  src="assets/icon1.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon3.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
              <Link to="/">
                <img
                  src="assets/icon2.svg"
                  className="h-[24px] w-[24px] object-cover"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-[20px]">
            <div className="flex flex-1 font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]">
              © 2022 ShopAd Ventures, all rights reserved
            </div>
            <div className="flex flex-1 sm:justify-end justify-center gap-[16px]">
              <Link
                to="/"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Home
              </Link>
              <Link
                to="/privacy-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Privacy Policy
              </Link>
              <Link
                to="/refund-policy"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Refund Policy
              </Link>
              <Link
                to="/terms-condition"
                className="font-[Outfit] font-[400] text-[16px] leading-[24px] text-[#606483]"
              >
                Terms and Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
